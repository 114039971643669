import { deleteObject, getBlob, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { firestore } from "./db";

// Create a root reference
const storage = getStorage();

const uploadFile = async (file, filePath) => {
  const fileRef = ref(storage, filePath)
  await uploadBytes(fileRef, file)
}

const deleteFile = async (filePath) => {
  const fileRef = ref(storage, filePath)
  await deleteObject(fileRef)
}

const downloadFile = async (filePath) => {
  const fileRef = ref(storage, filePath)
  return await getBlob(fileRef)
}

const fileManagement = {
  downloadNewMemberPacket: async () => {
    const currentPacket = await firestore.getNewMemberPacketFilename()
    const blob = await downloadFile("new-member-packet/" + currentPacket)
    return { blob: blob, filename: currentPacket }
  },
  uploadNewMemberPacket: async (file, filename) => {
    try {
      const currentPacket = await firestore.getNewMemberPacketFilename()
      if(currentPacket.length === 0) {
        await uploadFile(file, "new-member-packet/" + filename)
        await firestore.setNewMemberPacketFilename(filename)
      } else {
        await deleteFile("new-member-packet/" + currentPacket) 
        
        await uploadFile(file, "new-member-packet/" + filename)
        await firestore.setNewMemberPacketFilename(filename)
      }
    } catch (e) {
      if(e.code === "storage/object-not-found") {
        await uploadFile(file, "new-member-packet/" + filename)
        await firestore.setNewMemberPacketFilename(filename)
      } else {
        console.log(e)
        throw new Error(e.code)
      }
    }
  },
  uploadNewsfeedImage: async (file, filename, timestamp) => {
    await uploadFile(file, timestamp + "/" + filename)
  },
  getNewsfeedImageURL: async (filename) => {
    return await getDownloadURL(ref(storage, filename))
  }
}

export { fileManagement }