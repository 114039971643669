import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, startAfter, startAt, updateDoc } from "firebase/firestore";
import { app } from "./firebase";
import { fileManagement } from "./storage";

const db = getFirestore(app)

const firestore = {
  getNewMemberPacketFilename: async () => {
    const docRef = doc(db, "new-member-packet", "9jMax3Nk8sux0Pgwci25")
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data().file
    } else {
      // doc.data() will be undefined in this case
      throw new Error("New Member Packet does not exist")
    }
  },
  setNewMemberPacketFilename: async (filename) => {
    const docRef = doc(db, "new-member-packet", "9jMax3Nk8sux0Pgwci25")
    await updateDoc(docRef, {
      file: filename
    });
  },
  addNewsfeedPost: async (date, caption, file, filename) => {
    try {
      const timestamp = new Date().getTime().toString()

      const newsfeedPostRef = doc(db, "newsfeed-posts", timestamp)
      await setDoc(newsfeedPostRef, {date: date, caption: caption, filename: filename})
  
      await fileManagement.uploadNewsfeedImage(file, filename, timestamp)
    } catch (e) {
      throw new Error(e)
    }
  },
  getNewsfeedPosts: async (numberOfItems, setter, startIndex) => {
    var q
    if(startIndex === undefined) {
      q = query(collection(db, "newsfeed-posts"), 
        orderBy("date", "desc"),
        limit(numberOfItems + 1))
    } else {
      q = query(collection(db, "newsfeed-posts"), 
        orderBy("date", "desc"),
        startAt(startIndex),
        limit(numberOfItems + 1))
    }
    
    onSnapshot(q, (querySnapshot) => {
      const data = []
      var docSnap = null
      var index = 0
      querySnapshot.forEach(doc => {
        if(index < numberOfItems) {
          data.push({ id: doc.id, ...doc.data() })
        } else {
          docSnap = doc
        }
        index++
      })

     setter(data, docSnap)
    }) 
  },
  getPrevNewsfeedPosts: async (numberOfItems, setter, startIndex) => {
    var q
    if(startIndex === undefined) {
      return
    } else {
      q = query(collection(db, "newsfeed-posts"), 
        orderBy("date", "asc"),
        startAt(startIndex),
        limit(numberOfItems + 2))
    }
    
    onSnapshot(q, (querySnapshot) => {
      const data = []
      var docSnap = null
      var index = 0
      querySnapshot.forEach(doc => {
        if((index === 0 && startIndex === null) || (index === 1 && startIndex !== null)) {
          docSnap = doc
        } else if ((index > 0 && startIndex === null) || (index > 1 && startIndex !== null)) {
          data.push({ id: doc.id, ...doc.data() })
        }
        index++
      })

     setter(data, docSnap)
    }) 
  },
  deleteNewsfeedPost: async (docId) => {
    await deleteDoc(doc(db, "newsfeed-posts", docId))
  }
}

export { firestore }