import About from './About';
import GettingStarted from './GettingStarted';
import Header from './Header';
import Home from './Home';
import OurMission from './OurMission';
import Newsfeed from './Newsfeed';
import { useState } from 'react'

const UserView = ({logIn}) => {

  //for header
  const [showDropdown, setShowDropdown] = useState(false)
  const handleClick = (e) => {
    if(e === undefined || e.target.id !== "small_screen_icon") {
      setShowDropdown(false)
    }
  }

  return (
    <div onClick={e => handleClick(e)}>
      <Header showDropdown={showDropdown} setShowDropdown={setShowDropdown} logIn={logIn}/>
      <div id="home" style={{position: "relative", top: -50}}></div>
      <Home />
      <div id="our_mission" style={{position: "relative", top: -50}}></div>
      <OurMission />
      <div id="getting_started" style={{position: "relative", top: -50}}></div>
      <GettingStarted />
      <div id="about" style={{position: "relative", top: -50}}></div>
      <About />
      <div id="newsfeed" style={{position: "relative", top: -50}}></div>
      <Newsfeed />
    </div>
  )
}

export default UserView