import Button from "../Button"
import styles from "../../styles/LoggedIn.module.css"
import { useEffect, useRef, useState } from "react"
import { fileManagement } from "../../firebase/storage"
import Toast from "../Toast"

const NewMemberPacket = () => {

  const fileRef = useRef(0)
  
  const [file, setFile] = useState(null)
  const [filename, setFilename] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    if(file !== null && file !== undefined) {
      setFilename(file.name)
    } else {
      setFilename("")
    }
  }, [file])

  const upload = () => {
    fileManagement.uploadNewMemberPacket(file, filename)
      .then(() => {
        setFile(null)
        setMessage(filename + " was upload as the \"New Member Packet\"")
      })
      .catch(e => setMessage(e))
  }

  return (
    <>
      <div className={styles.header}>Upload New Member Packet</div>
      <div style={{display: "inline-flex", marginRight: 15}}>
        <Button onClick={() => fileRef.current.click() }>Select File</Button>
      </div>
      <span className={styles.filename}>{filename}</span>
      <div style={{float: "right"}}>
        <Button onClick={() => upload()}>Upload</Button>
      </div>

      <input type="file" style={{display: "none"}} ref={fileRef} onChange={e => setFile(e.currentTarget.files[0])}/>

      <Toast message={message} />
    </>
  )
}

export default NewMemberPacket