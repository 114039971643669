import Button from "../Button"
import styles from "../../styles/LoggedIn.module.css"
import { useEffect, useRef, useState } from "react"
import { firestore } from "../../firebase/db"
import Toast from "../Toast"

const NewsfeedPost = () => {

  const timezoneOffset = new Date().getTimezoneOffset() * 60000

  const fileRef = useRef(0)
  
  const [date, setDate] = useState(new Date(new Date().getTime() - timezoneOffset))
  const [caption, setCaption] = useState("")
  const [file, setFile] = useState(null)
  const [filename, setFilename] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    if(file !== null && file !== undefined) {
      setFilename(file.name)
    } else {
      setFilename("")
    }
  }, [file])

  const clearData = () => {
    setDate(new Date(new Date().getTime() - timezoneOffset))
    setCaption("")
    setFile(null)
  }

  const upload = () => {
    firestore.addNewsfeedPost(date, caption, file, filename)
      .then(() => {
        clearData()
        setMessage("A newsfeed post has been added")
      })
      .catch(e => setMessage(e))
  }
  
  return (
    <>
      <div className={styles.header} style={{marginBottom: 0}}>Upload Newsfeed Post</div>
      <input type="date" className={styles.newsletterDate} value={date.toISOString().substring(0, 10)} onChange={e => setDate(new Date(e.currentTarget.value))} />
      <textarea rows={5} onChange={e => setCaption(e.currentTarget.value)} value={caption}></textarea>
      <div style={{display: "inline-flex", marginRight: 15}}>
        <Button onClick={() => fileRef.current.click() }>Select File</Button>
      </div>
      <span className={styles.filename}>{filename}</span>
      <div style={{float: "right"}}>
        <Button onClick={() => upload()}>Upload</Button>
      </div>

      <input type="file" style={{display: "none"}} ref={fileRef} onChange={e => setFile(e.currentTarget.files[0])}/>

      <Toast message={message} />
    </>
  )
}

export default NewsfeedPost
