import { useEffect, useRef, useState } from "react"
import styles from "../styles/Button.module.css"

const Button = (props) => {
  const buttonRef = useRef(null)

  const [useParentFontSize, setUseParentFontSize] = useState(false)
  const [inlineStyle, setInlineStyle] = useState([])

  useIsOverflow(buttonRef, (isOverflow) => {
    setUseParentFontSize(isOverflow)
  })

  useEffect(() => {
    if(useParentFontSize) {
      setInlineStyle(prevState => {
        return { ...prevState, fontSize: "inherit" }
      })
    }

    if(props.color !== undefined && props.color === "black") {
      setInlineStyle(prevState => {
        return { ...prevState, backgroundColor: "black"}
      })
    } else if (props.color === "blue") {
      setInlineStyle(prevState => {
        return { ...prevState, backgroundColor: "#0246ce"}
      })
    }
  }, [useParentFontSize, props])

  return (
    <div onClick={() => props.onClick()} className={styles.button} style={inlineStyle} ref={buttonRef}>{props.children}</div>
  )
}

const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  useEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.clientWidth >= window.innerWidth

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, []);

  return isOverflow;
}

export default Button