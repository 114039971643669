// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6eyE6SgZwi6hhs9wy8rE0Q22gzazgx-k",
  authDomain: "razors-edge-boxing.firebaseapp.com",
  projectId: "razors-edge-boxing",
  storageBucket: "razors-edge-boxing.appspot.com",
  messagingSenderId: "862503403470",
  appId: "1:862503403470:web:fa68e2d0120e0a39af359f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export { app }