import styles from "../../styles/Header.module.css"
import dogLogo from "../../images/dog-logo.png"
import smallScreenIcon from "../../images/header_small_screen.png"

const Header = ({showDropdown, setShowDropdown, logIn}) => {

  const goTo = (page) => {
    document.getElementById(page).scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className={styles.header}>
      <div>
        <span className={styles.home} onClick={() => goTo("home")}>Home</span>
        <span className={styles.ourMission} onClick={() => goTo("our_mission")}>Our Mission</span>
        <span className={styles.gettingStarted} onClick={() => goTo("getting_started")}>Getting Started</span>
        <span className={styles.about} onClick={() => goTo("about")}>About</span>
        <span className={styles.newsfeed} onClick={() => goTo("newsfeed")}>Newsfeed</span>
      </div>

      <img src={smallScreenIcon} className={styles.smallScreenIcon} onClick={() => setShowDropdown(true)} id="small_screen_icon"/>
      <img src={dogLogo} onClick={() => logIn()}/>
      {showDropdown && 
        <div className={styles.dropdown}>
          <span onClick={() => goTo("home")}>Home</span>
          <span onClick={() => goTo("our_mission")}>Our Mission</span>
          <span onClick={() => goTo("getting_started")}>Getting Started</span>
          <span onClick={() => goTo("about")}>About</span>
          <span onClick={() => goTo("newsfeed")}>Newsfeed</span>
        </div>
      }
      
    </div>
  )
}

export default Header