import styles from "../../styles/About.module.css"
import Triangle from "./Triangle"
import facebook from "../../images/facebook.png"
import instagram from "../../images/instagram.png"
import venmo from "../../images/venmo.png"

const About = () => {
  return (
    <div className={styles.background} id="about">
      <Triangle className={styles.triangleBottom}/>
      <Triangle className={styles.triangleTop}/>
      <Box header="Hours">
        <div>
          Club:
          <div className={styles.bodySmaller}>
            Mon, Wed, Thurs,
            <br />
            Ages 8 - 13:  5:30 - 7:00 p.m.
            <br />
            14 & up:  7:00 - 8:30 p.m.
          </div>
        </div>
        
        <div>
          Private Lessons
          <div className={styles.bodySmaller}>
          By Appointment only
          <br/>
          Text Holly Gregson (208) 201-5896
          </div>
        </div>
      </Box>

      <Box header="Fees">
        <div>
          Club:
          <div className={styles.bodySmaller}>
            Individual - $65 a month<br />
            Group of 2 - $85 a month<br />
            Group of up to 4 - $130 a month
          </div>
        </div>

        <div>
          Registration USA Boxing:
          <div className={styles.bodySmaller}>
            Fitness membership (no sparring) - $35 per year<br />
            Competition registration - Begins at approximately $100 based on age (subject to change annually)
          </div>
        </div>
      </Box>

      <Box header="Contact Us" style={{marginLeft: 150}}>
        <div>
          Location:
          <div className={styles.bodySmaller}>
            757 S. Woodruff Ave. 
            <br />
            Idaho Falls, Idaho
          </div>
        </div>
        
        <div>
          Phone:
          <div className={styles.bodySmaller}>
            (208) 201-5896
          </div>
        </div>
      </Box>

      <Box header="Social">
        <img src={instagram} className={styles.icon} onClick={() => window.location.href = "https://www.instagram.com/razorsedgeboxing/"} />
        <img src={facebook} className={styles.icon} onClick={() => window.location.href = "https://www.facebook.com/Razors-Edge-Boxing-Club-131614530201140"} />
        <img src={venmo} className={styles.venmo} />
      </Box>
    </div>
  )
}

const Box = ({header, children, style}) => {
  return (
    <div className={styles.box} style={style}>
      <div className={styles.header}>{header}</div>
      <div className={styles.body}>{children}</div>
    </div>
  )
}


export default About