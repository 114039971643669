import { useState } from "react"
import ForgotPassword from "./ForgotPassword"
import Header from "./Header"
import Login from "./Login"
import LoggedIn from "./LoggedIn"

const AdminView = ({returnHome}) => {

  const [page, setPage] = useState("login")

  return (
    <div>
      <Header returnHome={returnHome} logout={() => setPage("login")}/>

      {page === "login" &&
        <Login loggedIn={() => setPage("loggedIn")} forgot={() => setPage("forgot")}/>
      }
      {page === "forgot" &&
        <ForgotPassword goToLogin={() => setPage("login")} />
      }
      {page === "loggedIn" &&
        <LoggedIn />
      }
    </div>
  )
}

export default AdminView