import { useEffect, useRef, useState } from "react"

const DownloadFile = ({blob, filename}) => {

  const ref = useRef(null)

  const [url, setUrl] = useState(null)

  useEffect(() => {
    if(blob !== null) {
      var data = new Blob([blob], {type: "application/pdf"})
      setUrl(window.URL.createObjectURL(data, { oneTimeOnly: true }))
    }
  }, [blob])

  useEffect(() => {
    if(url !== null) {
      ref.current.click()
    }
  }, [url])

  return (
    <a style={{display: "none"}} href={url} ref={ref} target="_blank"></a>
  )
}

export default DownloadFile