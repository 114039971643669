import styles from "../../styles/GettingStarted.module.css"
import stripes from "../../images/stripes.png"
import Button from "../Button"
import YoutubeEmbed from "./YoutubeEmbed"
import { useState } from "react"
import { fileManagement } from "../../firebase/storage"
import DownloadFile from "../DownloadFile"

const GettingStarted = () => {

  const [blob, setBlob] = useState(null)
  const [filename, setFilename] = useState(null)

  const downloadPacket = ()  => {
    fileManagement.downloadNewMemberPacket()
      .then(result => {
        setBlob(result.blob)
        setFilename(result.filename)
      })
  }

  return (
    <div className={styles.background}>
      <img className={styles.stripes} src={stripes} />
      <div className={styles.text}>
        <div className={styles.header}>Getting Started</div>
        <div className={styles.body}>
          Getting started with Razor’s Edge Boxing club is easy. We accept members ages 8 and up.  You will be extended two trial classes to try out the program!!!  
          We host our beginner's boxing boot camp the first week of every month (check the facebook page for any changes to this schedule).  Orientation lecture is 
          normally the first Monday of every month @ 4:30 p.m. All of those who are new to our club are required to attend on the first Monday, Wednesday, and 
          Thursday nights for your first trial sessions covering rules, fundamentals, and using the equipment.  You will also be given a members packet and given an 
          orientation that will go over club rules, paying club dues, and USA Boxing registration.  You are only required to attend one beginner's boot camp.  After 
          you have taken the fundamentals course, you will advance to the regular boxing sessions. At the end of your first week of boxing boot camp,  you will be 
          required to turn in paperwork and pay your first month's dues, this is how you join the club. The full class schedule is in the “about” section on this 
          page.  If you have a functional knowledge of boxing fundamentals and footwork, you will only be required to attend the Monday of boxing boot camp to go 
          over rules and safety policies before joining the regular boxing session.  Over the course of your first month, you will learn not only fundamentals and 
          how to safely use the equipment but also learn advanced technique, combinations and experience sparring ranging from beginners to intermediate.   We will 
          provide all gear you will need for your first day and will offer hand wraps for $15.00 per pair.  Otherwise, be sure you wear weather appropriate work-out 
          attire and bring a container for water.  If you have your own gloves and head gear, you are welcome to bring them to use and have evaluated for what 
          equipment they are appropriate for.  If you have a mouth piece, you are encouraged to bring it as well, but will not need one for your first week.  For 
          all other specific questions, you are encouraged to call Holly Gregson during weekday afternoons  208-201-5896.  Be sure to check the Facebook page for any 
          schedule changes before dropping in.  Any changes to the regular schedule and beginners schedule will be clearly posted on this page several hours prior to 
          class. More classes and opportunities will be added. Please stay tuned to the page for updates!
        </div>
      </div>
      
      <div className={styles.links}>
        <YoutubeEmbed className={styles.video} embedId="vUU-LdjzyoQ" />
        <div className={styles.buttonContainer}>
          <Button onClick={() => downloadPacket()}>Download Member's Packet</Button>
        </div>
      </div>
      
      <DownloadFile blob={blob} filename={filename} />
    </div>
  )
}
 
export default GettingStarted