import { useEffect, useState } from "react"
import { firestore } from "../../firebase/db"
import styles from "../../styles/LoggedIn.module.css"

const DeletePost = () => {

  const [startAt, setStartAt] = useState(undefined)
  const [posts, setPosts] = useState([])
  const [firstPage, setFirstPage] = useState(false)
  const [lastPage, setLastPage] = useState(false)
  const [somewhereInTheMiddle, setSomewhereInTheMiddle] = useState(false)
  const [firstPostId, setFirstPostId] = useState(0)
  
  useEffect(() => {
    getData()
  }, [])

  const firebaseSetter = (data, docSnap) => {
    figureOutWhichPageThisIs(data, docSnap)
    setPosts(data)
    setStartAt(docSnap)
  }

  const figureOutWhichPageThisIs = (data, docSnap) => {
    var notInTheMiddle = false
    if((!firstPage && !somewhereInTheMiddle && !lastPage) || data[0].id === firstPostId) {
      setFirstPostId(data[0].id)
      setFirstPage(true)
      notInTheMiddle = true
    } 
    
    if(docSnap === null) {
      setLastPage(true)
      setSomewhereInTheMiddle(false)
      notInTheMiddle = true
    }
    
    if(!notInTheMiddle) {
      setFirstPage(false)
      setLastPage(false)
      setSomewhereInTheMiddle(true)
    }
  }

  const getData = () => {
    firestore.getNewsfeedPosts(10, firebaseSetter, startAt)
  }

  return (
    <>
      <div className={styles.header}>Delete Post</div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Caption</th>
            <th>Image</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {posts.map(post => (
            <tr>
              <td>{post.date.toDate().toDateString()}</td>
              <td>{post.caption}</td>
              <td>{post.filename}</td>
              <td>
                <a onClick={() => firestore.deleteNewsfeedPost(post.id)}>X</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!firstPage && 
        <a className={styles.loadMorePosts} onClick={() => getData()}>{"<"}</a>
      }
      {!lastPage &&
        <a className={styles.loadMorePosts} onClick={() => getData()}>{">"}</a>
      }
    </>
  )
}

export default DeletePost
