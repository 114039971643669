import Button from "../Button"
import styles from "../../styles/LoggedIn.module.css"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import Toast from "../Toast";


const AddAdmin = () => {
  
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("") 
  const [message, setMessage] = useState("")

  const addAdmin = () => {
    if(password !== confirmPassword) {
      setMessage("Passwords don't match")
    } else {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          setMessage(user.email + " has been added as an admin")
        })
        .catch((error) => {
          const errorCode = error.code;
          if(errorCode === "auth/invalid-email") {
            setMessage("Please use a valid email address")
          } else if(errorCode === "auth/missing-email") {
            setMessage("No Email Address entered")
          } else if(errorCode === "auth/weak-password") {
            setMessage("Password must be 6 or more characters")
          } else {
            setMessage(errorCode)
          }
        });
    }
  }
   
  return (
    <>
      <div className={styles.header}>Add an Admin User</div>
      <label>Email:</label>
      <input type="text" onChange={e => setEmail(e.currentTarget.value)}/>
      <label>Password:</label>
      <input type="password" onChange={e => setPassword(e.currentTarget.value)} style={{marginBottom: 15}}/>
      <label>Confirm Password:</label>
      <input type="password" onChange={e => setConfirmPassword(e.currentTarget.value)} style={{marginBottom: 15}}/>
      <div style={{float: "right"}}>
        <Button onClick={() => addAdmin()}>Add</Button>
      </div>

      <Toast message={message} />
    </>
  )
}

export default AddAdmin