import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, className }) => (
  <div style={{display: "inline-block"}} className={className}>
    <iframe
      style={{left: 0, top: 0, height: "330px", width: "100%", position: "absolute"}}
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;