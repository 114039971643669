import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({message}) => {
  useEffect(() => {
    if(message.length > 0) {
      toast(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [message])

  return (
    <ToastContainer toastClassName="toast"/>
  )
}

export default Toast