import styles from "../../styles/LoggedIn.module.css"
import AddAdmin from "./AddAdmin"
import DeletePost from "./DeletePost"
import NewMemberPacket from "./NewMemberPacket"
import NewsfeeedPost from "./NewsfeedPost"

const LoggedIn = () => {
  return (
    <div className={styles.background}>
      <NewMemberPacket />

      <br /><br /><br /><br />
      <NewsfeeedPost />

      <br /><br /><br /><br />
      <AddAdmin />

      <br /><br /><br /><br />
      <DeletePost />
    </div>
  )
}

export default LoggedIn