import styles from "../../styles/Header.module.css"
import dogLogo from "../../images/dog-logo.png"

const Header = ({returnHome, logout}) => {

  return (
    <div className={styles.header}>
      <span className={styles.homeAdmin} onClick={() => returnHome()}>Home</span>
      <img src={dogLogo} onClick={() => logout()}/>
    </div>
  )
}

export default Header