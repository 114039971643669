import styles from "../../styles/ForgotPassword.module.css"
import { useState } from "react"
import Button from "../Button"
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Toast from "../Toast";

const ForgotPassword = ({goToLogin}) => {

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const sendEmail = () => {
    const auth = getAuth()
    sendPasswordResetEmail(auth, email)
    .then(() => {
      setMessage("A password reset email has been sent. If you can't find it look in your spam folder")
      setTimeout(goToLogin, 3000);
    })
    .catch((error) => {
      const errorCode = error.code;
      if(errorCode === "auth/invalid-email") {
        setMessage("Please use a valid email address")
      } else if(errorCode === "auth/user-not-found") {
        setMessage("You are not a register admin")
      } else {
        setMessage(errorCode)
      }
    });
  }

  return (
    <div className={styles.background}>
      <div className={styles.box}>
        <label>Email:</label>
        <input type="text" onChange={e => setEmail(e.currentTarget.value)}/>

        <div className={styles.cancel} onClick={() => goToLogin()}>Cancel</div>
        <div className={styles.buttonContainer}>
          <Button color="blue" onClick={() => sendEmail()}>Send Password Reset Email</Button>
        </div>
      </div>

      <Toast message={message} />
    </div>
  )
}

export default ForgotPassword