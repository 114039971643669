import styles from "../../styles/Login.module.css"
import Button from "../Button"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import Toast from "../Toast";

const Login = ({loggedIn, forgot}) => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const login = () => {
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        loggedIn()
      })
      .catch((error) => {
        const errorCode = error.code;
        if(errorCode === "auth/invalid-email") {
          setErrorMessage("Please use a valid email address")
        } else if(errorCode === "auth/user-not-found") {
          setErrorMessage("You are not a register admin")
        } else if(errorCode === "auth/wrong-password") {
          setErrorMessage("Wrong Password")
        } else {
          setErrorMessage(errorCode)
        }
      });
  }

  return (
    <div className={styles.background}>
      <div className={styles.box}>
        <label>Email:</label>
        <input type="text" onChange={e => setEmail(e.currentTarget.value)}/>

        <label>Password:</label>
        <input type="password" onChange={e => setPassword(e.currentTarget.value)}/>

        <div className={styles.forgot} onClick={() => forgot()}>Forgot Password</div>
        <div className={styles.buttonContainer}>
          <Button color="blue" onClick={() => login()}>Login</Button>
        </div>
      </div>

      <Toast message={errorMessage} />
    </div>
  )
}

export default Login