import styles from "../../styles/Home.module.css"
import boxingWraps from "../../images/boxing-wraps.png"
import logo from "../../images/logo.png"

const Home = ({ref}) => {
  return (
    <div className={styles.background}>
      <img src={logo} className={styles.logo} />
    </div>
  )
}

export default Home