import styles from "../../styles/Newsfeed.module.css"
import stripes from "../../images/stripes.png"
import arrow from "../../images/arrow.png"
import loading from "../../images/loading.gif"
import { useEffect, useState } from "react"
import { firestore } from "../../firebase/db"
import { fileManagement } from "../../firebase/storage"


const Newsfeed = () => {

  const [startAt, setStartAt] = useState(undefined)
  const [firstPage, setFirstPage] = useState(false)
  const [lastPage, setLastPage] = useState(false)
  const [somewhereInTheMiddle, setSomewhereInTheMiddle] = useState(false)
  const [firstPostId, setFirstPostId] = useState(0)

  const [post, setPost] = useState(null)
  const [img, setImg] = useState("")

  useEffect(() => {
    getNextPost()
  }, [])

  useEffect(() => {
    if(post !== null) {
      setImg(loading)
      fileManagement.getNewsfeedImageURL(post.id + "/" + post.filename)
        .then(url => setImg(url))
    }
  }, [post])

  const firebaseSetter = (data, docSnap) => {
    figureOutWhichPageThisIs(data, docSnap)
    if(data.length > 0) {
      setPost(data[0])
    } else {
      setPost(null)
    }
    setStartAt(docSnap)
  }

  const figureOutWhichPageThisIs = (data, docSnap) => {
    var notInTheMiddle = false
    if(data.length === 0) {
      setFirstPage(true)
      setLastPage(true)
      return
    }

    if((!firstPage && !somewhereInTheMiddle && !lastPage) || data[0].id === firstPostId) {
      setFirstPostId(data[0].id)
      setFirstPage(true)
      notInTheMiddle = true
    } 
    
    if(docSnap === null) {
      setLastPage(true)
      setSomewhereInTheMiddle(false)
      notInTheMiddle = true
    }
    
    if(!notInTheMiddle) {
      setFirstPage(false)
      setLastPage(false)
      setSomewhereInTheMiddle(true)
    }
  }

  const getNextPost = () => {
    firestore.getNewsfeedPosts(1, firebaseSetter, startAt)
  }

  const getPrevPost = () => {
    firestore.getPrevNewsfeedPosts(1, firebaseSetter, startAt)
  }

  return (
    <div className={styles.background} id="newsfeed">
      <div className={styles.header}>Newsfeed</div>
      <img className={styles.stripes} src={stripes} />
      {!firstPage &&
        <img className={styles.arrowLeft} src={arrow} onClick={() => getPrevPost()}/>
      }
      {!lastPage &&
        <img className={styles.arrowRight} src={arrow} onClick={() => getNextPost()}/>
      }
      <div className={styles.arrowDescription}>Click to Scroll Through Posts</div>

      {post !== null &&
        <div className={styles.post}>
          <div className={styles.imgContainer}>
            <img src={img} />
          </div>
          <div>
            <div className={styles.text}>
              <div className={styles.date}>Date: {post.date.toDate().toDateString()}</div>
              <br />
              <div className={styles.caption}>{post.caption}</div>
            </div>
          </div>
        </div>
      }

      {post === null &&
        <div className={styles.noInfo}>No information to show. Check back later</div>
      }
    </div>
  )
}

export default Newsfeed