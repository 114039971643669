import { useState } from 'react';
import AdminView from './components/AdminView';
import UserView from './components/UserView';

function App() {

  const [view, setView] = useState("user")

  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Barlow+Semi+Condensed:wght@600&display=swap" rel="stylesheet" />
      {view === "user" &&
        <UserView logIn={() => setView("admin")}/>
      }
      {view === "admin" &&
        <AdminView returnHome={() => setView("user")}/>
      }
    </div>
  );
} 

export default App;
