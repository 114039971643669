import styles from "../../styles/OurMission.module.css"
import Triangle from "./Triangle"


const OurMission = () => {
  return (
    <div className={styles.background}>
      <Triangle className={styles.triangleBottom}/>
      <Triangle className={styles.triangleTop}/>
      <div className={styles.text}>
        <div className={styles.header}>Our Mission</div>
        <div className={styles.body}>Proud to represent Idaho Boxing!!! We offer experienced instruction in Olympic style boxing with the emphasis on competition and benefits in conditioning and weight loss.</div>
        <br/>
        <div className={styles.body}>Come be a part of boxing in Idaho Falls. We have been established, functioning, and dependable since 2007. We are experienced and have a full understanding of USA Boxing rules and regulations. We train in a safe and clean facility fit for training and learning. Our facility is equipped with adequate space and bathrooms.</div>
      </div>
    </div>
  )
}

export default OurMission